import React, { createContext, useContext } from 'react';

import type { LoadingBarRef } from 'react-top-loading-bar';

type TopLoaderType = {
  loadBarRef: React.RefObject<LoadingBarRef>;
};

export const TopLoaderContext = createContext<TopLoaderType | null>(null);

export const useTopLoader = () => useContext(TopLoaderContext);
