import { THEME, updateTheme } from '@api/local/theme';

export const changeTheme = (theme: THEME) => {
  if (theme === THEME.light) {
    document.documentElement.removeAttribute('data-theme');
    document.documentElement.setAttribute('data-theme', 'light');
  } else {
    document.documentElement.removeAttribute('data-theme');
    document.documentElement.setAttribute('data-theme', 'dark');
  }

  updateTheme(theme);
};

if (typeof window !== 'undefined') {
  let theme = 'dark'; //default to dark

  //local storage is used to override OS theme settings
  const savedTheme = JSON.parse(localStorage.getItem('_local/theme') || '{}')?.theme;

  if (savedTheme) {
    if (savedTheme === 'light') {
      theme = 'light';
    }
  }

  if (theme == 'light') {
    document.documentElement.setAttribute('data-theme', theme);
  }
}
