import { getServer } from '@repugraf/cross-domain-storage';
import dayjs from 'dayjs';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import Script from 'next/script';
import React, { useEffect, useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';

import { TopLoaderContext } from '@src/context/TopLoaderContext';
import { MediaContextProvider } from '@src/utils/media';
import { getSearchParam } from '@src/utils/searchParams';
import '@src/utils/themeChanger';

import type { LoadingBarRef } from 'react-top-loading-bar';

import 'react-toastify/dist/ReactToastify.css';
import 'url-search-params-polyfill';
import 'src/styles/global.sass';

if (typeof window !== 'undefined') {
  const server = getServer({
    allowedDomains: [
      {
        origin: /zionodes.com$/,
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: /www.zionodes.com$/,
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: /es.zionodes.com$/,
        allowedMethods: ['get', 'set', 'remove'],
      },
      {
        origin: /www.es.zionodes.com$/,
        allowedMethods: ['get', 'set', 'remove'],
      },
    ],
  });

  server.listen();
}

const lngLocale = {
  zh: 'zh-cn',
  en: 'en',
};

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
  const { i18n } = useTranslation();
  const router = useRouter();
  const { locale } = router;

  const utmSource = getSearchParam('utm_source');
  const utmMedium = getSearchParam('utm_medium');
  const utmCampaign = getSearchParam('utm_campaign');

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [i18n, locale]);

  useEffect(() => {
    const handleLanguageChanged = async (lng: string) => {
      if (lng === 'zh') {
        await import('dayjs/locale/zh-cn');
      }

      dayjs.locale(lngLocale[lng as keyof typeof lngLocale]);
    };
    i18n.on('languageChanged', handleLanguageChanged);

    handleLanguageChanged(i18n.language);
    return () => i18n.off('languageChanged', handleLanguageChanged);
  }, [i18n]);

  useEffect(() => {
    //@ts-ignore
    if (!window.landingURL) {
      //@ts-ignore
      window.landingURL = window.location.href;
    }

    //@ts-ignore
    if (utmMedium && !window.utmMedium) {
      //@ts-ignore
      window.utmMedium = utmMedium;
    }

    //@ts-ignore
    if (utmCampaign && !window.utmCampaign) {
      //@ts-ignore
      window.utmCampaign = utmCampaign;
    }

    //@ts-ignore
    if (utmSource && !window.utmSource) {
      //@ts-ignore
      window.utmSource = utmSource;
    }
  }, [utmMedium, utmCampaign, utmSource]);

  const loadBarRef = useRef<LoadingBarRef>(null);

  Router.events.on('routeChangeStart', () => {
    // This is react-top-loading-bar typescript bug, in fact it has 2 OPTIONAL arguments, it is described in documentation
    // https://github.com/klendi/react-top-loading-bar
    // @ts-ignore
    loadBarRef.current?.continuousStart();
  });

  Router.events.on('routeChangeComplete', () => {
    loadBarRef.current?.complete();
  });

  return (
    <>
      {/* Google Tag Manager */}
      <Script id="google-tag" strategy="afterInteractive">{`
      (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      j.async = true;
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-KMG2RL4')`}</Script>
      {/* End Google Tag Manager */}

      <MediaContextProvider>
        <TopLoaderContext.Provider value={{ loadBarRef }}>
          <LoadingBar color="var(--wt-blue3-dt-blue1)" ref={loadBarRef} />
          <Component {...pageProps} />
        </TopLoaderContext.Provider>
      </MediaContextProvider>

      {process.env.APP_ENV === 'production' && (
        <>
          <Script
            strategy="lazyOnload"
            id="ze-snippet"
            //@ts-ignore
            rel="dns-prefetch"
            type="text/javascript"
            charSet="utf-8"
            src="https://static.zdassets.com/ekr/snippet.js?key=1b0ef71a-a36d-4002-808f-38e8f8011e99"
            async={true}
          />
          <Script
            src="https://my.hellobar.com/1a06fa0ad0aac81d21ed6f143993360e67e7291b.js"
            strategy="lazyOnload"
            id="ze-snippet"
            //@ts-ignore
            rel="dns-prefetch"
            type="text/javascript"
            charSet="utf-8"
            async={true}
          />
        </>
      )}
    </>
  );
};

export default appWithTranslation(App);
