import { createMedia } from '@artsy/fresnel';
import { MediaContextProviderProps } from '@artsy/fresnel/dist/Media';
import React from 'react';

const AppMedia = createMedia({
  breakpoints: {
    sm: 0,
    phone: 426,
    lPhone: 621,
    tablet: 769,
    lTablet: 1025,
    desktop: 1367,
  },
});

// Make styles for injection into the header of the page
export const mediaStyles = AppMedia.createMediaStyle();

const { Media, MediaContextProvider: MediaProvider } = AppMedia;

const MediaContextProvider = MediaProvider as React.ComponentType<
  MediaContextProviderProps<'sm' | 'phone' | 'lPhone' | 'tablet' | 'lTablet' | 'desktop'> & {
    children: React.ReactNode;
  }
>;

export { Media, MediaContextProvider };
