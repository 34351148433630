import queryString from 'querystring';

import Router from 'next/router';

export const getSearchParam = (param: string): string | undefined => {
  if (typeof window !== 'undefined') {
    const paramValue = new URLSearchParams(window.location.search).get(param) || '';
    return paramValue;
  }
  return undefined;
};

export const setSearchParams = (
  params: Record<string, string | number | boolean | undefined>,
  replace: boolean = false,
  shallow: boolean = false,
) => {
  const currentPath = new URL(window.location.href);
  const query = { ...Router.query };
  if (replace) {
    currentPath.search = `?${queryString.stringify(params)}`;
  } else {
    const mergedParams = new URLSearchParams(window.location.search);
    Object.entries(params).forEach(([paramName, paramValue]) => {
      if (paramValue) {
        query[paramName] = String(paramValue);
        mergedParams.set(paramName, String(paramValue));
      } else {
        delete query[paramName];
        mergedParams.delete(paramName);
      }
    });
    currentPath.search = mergedParams.toString();
  }
  Router.push(
    {
      query,
      search: currentPath.search,
    },
    currentPath.pathname + currentPath.search,
    { shallow },
  );
};
