import useSWR, { mutate } from 'swr';

export enum THEME {
  light = 'light',
  dark = 'dark',
}

export const updateTheme = (theme: THEME) => {
  mutate(
    '_local/theme',
    () => {
      // Save theme
      localStorage.setItem('_local/theme', JSON.stringify({ theme }));
    },
    true,
  );
};

export const useCurrentTheme = () => {
  const info = useSWR<{ theme: THEME }>('_local/theme', (key) => {
    let theme = THEME.dark; //default to dark

    //local storage is used to override OS theme settings
    const savedTheme = JSON.parse(localStorage?.getItem(key) || '{}')?.theme;

    if (savedTheme) {
      if (savedTheme === THEME.light) {
        theme = THEME.light;
      }
    }

    return { theme };
  });

  return info;
};
